// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full {
    height: 100%;
    width: 100%;
}

.image {
    object-fit: contain;
    display: block;
    flex: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.size-option {
    display: flex;
}

.no-slideshow {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.left-overlay {
    left: 0;
}

.right-overlay {
    right: 0;
}

.top-overlay {
    top: 0;
}

.bottom-overlay {
    bottom: 0;
}

.div-container {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.main-container {
    /* Other than hidden -> scroll bar ? */
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
}

/* Fix slideshow height (is there a better way of doing that ?) */
#slideshow-wrapper div {
    height: inherit;
}
`, "",{"version":3,"sources":["webpack://./css/image.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,UAAU;IACV,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,MAAM;AACV;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,sCAAsC;IACtC,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;AAChB;;AAEA,iEAAiE;AACjE;IACI,eAAe;AACnB","sourcesContent":[".full {\n    height: 100%;\n    width: 100%;\n}\n\n.image {\n    object-fit: contain;\n    display: block;\n    flex: none;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.size-option {\n    display: flex;\n}\n\n.no-slideshow {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: flex-start;\n}\n\n.left-overlay {\n    left: 0;\n}\n\n.right-overlay {\n    right: 0;\n}\n\n.top-overlay {\n    top: 0;\n}\n\n.bottom-overlay {\n    bottom: 0;\n}\n\n.div-container {\n    display: flex;\n    flex-direction: column;\n    margin: 5px;\n}\n\n.main-container {\n    /* Other than hidden -> scroll bar ? */\n    overflow: hidden;\n    overflow-y: auto;\n    height: 100%;\n}\n\n/* Fix slideshow height (is there a better way of doing that ?) */\n#slideshow-wrapper div {\n    height: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
